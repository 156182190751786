import React from "react";

function NoMatch() {
  return (
    <div className="pageNotFound">
      <h2>Page Not found</h2>
    </div>
  );
}

export default NoMatch;
