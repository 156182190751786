import React from "react";
import * as emailjs from "emailjs-com";
import RichText from "@madebyconnor/rich-text-to-jsx";

import { useContentfulState } from "../providers/Contentful";
import Loading from "../component/Loading";

function Home() {
  const { loading, data } = useContentfulState();

  if (loading) return <Loading />;

  const handleFormSubmit = event => {
    event.preventDefault && event.preventDefault();
    const templateParams = {
      name: event.target.name.value,
      email: event.target.email.value,
      message: event.target.message.value
    };

    const formEl = event.target;
    formEl.classList.remove("sendError");

    emailjs
      .send(
        "mailgun",
        "contact_us_form",
        templateParams,
        "user_pPpMC5lhTFIBUMdArgs5b"
      )
      .then(
        response => {
          console.log("SUCCESS!", response.status, response.text);
          formEl.classList.add("sendSuccess");

          formEl.name.value = "";
          formEl.email.value = "";
          formEl.message.value = "";
        },
        error => {
          console.log("FAILED...", error);
          formEl.classList.add("sendError");
        }
      );
  };

  return (
    <div>
      <section>
        <div className="section_bigtop">
          <div
            style={{
              backgroundImage: `url(https://${data.bigTop.fields.file.url})`
            }}
          />
        </div>
      </section>
      {Object.keys(data.content).map(key => {
        const content = data.content[key];
        const type = content.sys.contentType.sys.id;
        const section = content.fields;
        return (
          <section id={section.id} key={section.id}>
            <div>
              <h1>{section.title}</h1>
              <div>
                <RichText richText={section.text} />

                {type === "galleries" &&
                  section.galleries.map(gallery => {
                    console.log("gallery: ", gallery);
                    if (!gallery.fields) {
                      return (
                        <p className="galleryItem" key={gallery.sys.id}>
                          Error
                        </p>
                      );
                    }
                    console.group();
                    console.log("gallery: ", gallery.fields);
                    console.log(
                      "gallery.fields.bigTop.fields.file.url: ",
                      gallery.fields.bigTop.fields.file.url
                    );
                    console.groupEnd();
                    return (
                      <a
                        href={`/${gallery.fields.id}`}
                        key={gallery.sys.id}
                        className="galleryItem"
                      >
                        <div
                          className="photo"
                          style={{
                            backgroundImage: `url(https://${
                              gallery.fields[
                                gallery.fields.thumb ? "thumb" : "bigTop"
                              ].fields.file.url
                            }?w=260)`
                          }}
                        />
                        <dl>
                          <dt>{gallery.fields.title}</dt>
                          <dd>{gallery.fields.imagesObj.length} photos</dd>
                        </dl>
                      </a>
                    );
                  })}

                {type === "form" && (
                  <form
                    id="emailForm"
                    className="feedback-form"
                    onSubmit={handleFormSubmit}
                  >
                    {section.inputs.map(form => {
                      return (
                        <p key={`id${form.sys.id}`}>
                          {form.fields.type !== "textarea" && (
                            <input
                              type={form.fields.type}
                              id={form.sys.id}
                              placeholder={form.fields.placeholder}
                              name={form.fields.name}
                              required={form.fields.required}
                            />
                          )}
                          {form.fields.type === "textarea" && (
                            <textarea
                              type={form.fields.type}
                              rows="6"
                              id={form.sys.id}
                              placeholder={form.fields.placeholder}
                              name={form.fields.name}
                              required={form.fields.required}
                            />
                          )}
                        </p>
                      );
                    })}
                    <p>
                      <input id="emailButton" type="submit" value="Send" />
                    </p>
                    <p className="successMessage">{section.successText}</p>
                    <p className="errorMessage">{section.errorText}</p>
                  </form>
                )}
              </div>
            </div>
          </section>
        );
      })}
    </div>
  );
}
export default Home;
