import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import CookieBanner from "react-cookie-banner";
import RichText from "@madebyconnor/rich-text-to-jsx";

import ContentfulProvider, { useContentfulState } from "./providers/Contentful";
import HomePage from "./pages/Home";
import GalleryPage from "./pages/Gallery";
import NoMatch from "./pages/NoMatch";
import PrivacyPage from "./pages/Privacy";
import Loading from "./component/Loading";

import "@zhyabs1314/react-image-lightbox/style.css";
import "./App.css";

function Routes() {
  const { loading, data, gallery: galleries, isPreview } = useContentfulState();
  if (loading) return <Loading />;

  const galleryKeys = Object.keys(galleries).map(key => key);
  const navigation = data.content.map(content => (
    <li key={content.fields.id}>
      <HashLink to={`/#${content.fields.id}`}>{content.fields.title}</HashLink>
    </li>
  ));

  return (
    <div>
      <header>
        <div>
          <div className="headline">
            <div id="header_morebutton" className="morebutton" />
            <a href="/">
              <h1>
                {data.title}
                {isPreview ? " Preview (content may not be published)" : ""}
              </h1>
              <h2>{data.description}</h2>
            </a>
          </div>
          <ul className="navigation">{navigation}</ul>
        </div>
      </header>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/privacy" component={PrivacyPage} />
        <Route
          path={`/:gallery(${galleryKeys.join("|").toLocaleLowerCase()})`}
          component={GalleryPage}
        />
        <Route component={NoMatch} />
      </Switch>
      <footer>
        <div>
          <RichText richText={data.footer} />
          <p>
            <a
              className="right"
              href="http://peranosborn.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Site by Peran Osborn
            </a>
          </p>
        </div>
      </footer>
      <CookieBanner
        message="This website uses cookies to ensure you get the best experience on our website"
        link={<a href="/privacy">More info</a>}
        buttonMessage="Got it!"
        dismissOnScroll={false}
        dismissOnClick
        onAccept={() => {}}
        disableStyle
      />
    </div>
  );
}

function App() {
  return (
    <Router>
      <ContentfulProvider>
        <Routes />
      </ContentfulProvider>
    </Router>
  );
}

export default App;
