import React, { useContext, useEffect, useState } from "react";
import { createClient } from "contentful";

const ContentfulContext = React.createContext(null);

// Data from Contentful
const SPACE_ID = "8mus19ckwapy";
const ACCESS_TOKEN = "ng1tyrgDvF_sc0efZnBEMEvitqGxxwc5K_3ZrzJNV80";
const PREVIEW_ACCESS_TOKEN = "FV8CpVMXvorG11PNtTWssMhd1eDLi5FnqbM7ZjsCgS8";

const isPreview = ["xx.dev.mickdoyle.co.uk", "xxlocalhost"].includes(
  window.location.hostname
);
const client = createClient({
  space: SPACE_ID,
  accessToken: isPreview ? PREVIEW_ACCESS_TOKEN : ACCESS_TOKEN
});

function useContentfulState() {
  const context = useContext(ContentfulContext);
  if (!context) {
    throw new Error("Can't use without a provider");
  }
  return context;
}

function ContentfulProvider({ children }) {
  const [data, setData] = useState({});
  const [gallery, setGalleries] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    client
      .getEntries({
        content_type: "homePageLayout",
        include: 4
      })
      .then(entries => {
        console.log("entries: ", entries);
        console.log("entries.items[0].fields: ", entries.items[0].fields);
        setData(entries.items[0].fields);

        const galleries = {};
        entries.includes.Entry.forEach(item => {
          if (item.sys.contentType.sys.id === "gallery") {
            galleries[item.fields.id] = item.fields;
          }
        });
        setGalleries(galleries);
        setLoading(false);
      });
  }, []);

  const stateValue = {
    data,
    gallery,
    loading,
    isPreview
  };

  return (
    <ContentfulContext.Provider value={stateValue}>
      {children}
    </ContentfulContext.Provider>
  );
}

export { useContentfulState };
export default ContentfulProvider;
