import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Lightbox from "@zhyabs1314/react-image-lightbox";

import { useContentfulState } from "../providers/Contentful";
import Loading from "../component/Loading";
import NoMatch from "./NoMatch";

function Gallery() {
  const { gallery: galleryId } = useParams();
  console.log("galleryId: ", galleryId);

  const { loading, gallery } = useContentfulState();
  const [photoIndex, setPhotoIndex] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  if (loading) return <Loading />;

  const data = gallery[galleryId];
  console.log("data: ", data);
  const imageUrls = [];

  if (!data) {
    return <NoMatch />;
  }

  const images = data.imagesObj;
  return (
    <div>
      <section>
        <div className="section_bigtop">
          <div
            style={{
              backgroundImage: `url(https:${data.bigTop.fields.file.url})`
            }}
          />
        </div>
      </section>
      <section id="galleries" className="gallery_page">
        <div className="section_gallery">
          <h1>{data.title}</h1>
          <p>{data.description}</p>
          <div>
            {images.map((image, i) => {
              imageUrls.push(`https:${image.fields.image.fields.file.url}`);
              return (
                <div
                  key={image.sys.id}
                  className="galleryItem"
                  onClick={() => {
                    setIsOpen(true);
                    setPhotoIndex(i);
                  }}
                >
                  <div
                    className="photo"
                    style={{
                      backgroundImage: `url(https:${image.fields.thumb.fields.file.url}?w=260)`
                      // backgroundPosition: `${image.position.x} ${image.position.y}`,
                    }}
                  />
                  <dl>
                    <dt>{image.fields.title}</dt>
                    <dd>{image.fields.description}</dd>
                  </dl>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      {isOpen && (
        <Lightbox
          mainSrc={imageUrls[photoIndex]}
          discourageDownloads
          enableZoom
          imageTitle={`${data.title} - ${images[photoIndex].title}`}
          imageCaption={images[photoIndex].description}
          nextSrc={imageUrls[(photoIndex + 1) % imageUrls.length]}
          prevSrc={
            imageUrls[(photoIndex + imageUrls.length - 1) % imageUrls.length]
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + imageUrls.length - 1) % imageUrls.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % imageUrls.length)
          }
        />
      )}
    </div>
  );
}

export default Gallery;
