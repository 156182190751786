import React from "react";
import RichText from '@madebyconnor/rich-text-to-jsx';

import { useContentfulState } from '../providers/Contentful';
import Loading from '../component/Loading';

function Privacy() {
  const { loading,  data } = useContentfulState();
  console.log('data: ', data.privacy.fields.title);

  if (loading) return <Loading />;

  return (
    <section id="privacy">
      <div>
        <div>
          <h1>{data.privacy.fields.title}</h1>
          <RichText richText={data.privacy.fields.text} />
        </div>
      </div>
    </section>
  );
}

export default Privacy;
